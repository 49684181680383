<template >

<div class="cell small-12 medium-10 ">


    <div id="oddstable" class="table-scroll grid-container" :key="loadstage">

            <template v-if="oddsloaded">    
    
            <h1 class="qmajorheader">{{competition}} - {{betinfo[0]}}</h1> 


            <!-- other outright type dropdown -->
            <div class="grid-x">

                <!-- <div class="cell small-3"><dropdown @sort_value="sort" ocdefaultvalue="sort by" ocname="outrightsort" :ocoptions="filteroptions" :ocnoitems="10"></dropdown></div>
                <div class="cell small-3"></div> -->

             
                <div class="cell auto qlegendfont oddsbest">Best odds</div>
                        <div class="cell auto qlegendfont oddsout">Odds going up</div>
                        <div class="cell auto qlegendfont oddsin">Odds going down</div>
                        <div class="cell small-5 "><dropdown ocdefaultvalue="Other Outrights" ocname="OtherOutrightsForLeague" :ocid="ocorid" :ocnoitems="10"></dropdown></div>

            </div>

            <!-- Bookmaker code -->
            <div class="grid-x" style="margin-bottom: 1px;border-bottom:1px  #E6E7E9 solid;" >

               <div class="cell small-2"></div>

                <template v-for="(row,key) in oddsheaders">
                    <div v-if="key !== 'BO' && key !== 'Competitor' && donotDisplayColumn(key) == false " :class="'cr' + key + ' cell shrink text-center'" style="padding:0px 5px 0px 5px;" :key="key" >   
                        <a :href="'/bookmakerloader.html?bkm=' + key + '&country=' + $store.state.countryname" target="_blank" >              
                            <span :class="'vb' + key"></span>
                        </a>
                    </div>    
                </template>

            </div>

            <!---promos-->

            <div class="grid-x" style="border-bottom:1px  #E6E7E9 solid;">

                <div class="cell small-2">

                <div class="grid-y">
                    <div @mouseover="mouseQuadrant"   :class="{tooltip: hints}" class="cell qlegendfont float-right qnewplayers qwhitetext text-center" style="height:20px;width:100%">New 
                        <span class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}">These promotions are only available to those who are, for the first time, opening an account</span>
                    </div>

                    <div @mouseover="mouseQuadrant"    :class="{tooltip: hints}" class="cell qlegendfont  qallplayers qwhitetext text-center " style="height:20px;width:100%">All
                        <span class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}" >These promotions are available to all customers and are very likely to be specific to this event (or competition)</span>   

                    </div> 
                </div>
              
                </div>
                <template v-if="promoheadersloaded">
                    <div v-for="(row,i) in promoheaders" :key="i">
                    
                    <div  class="cell shrink text-center" style="padding:0px 13px 2px 13px;"   >
                          
                            <div v-if="row.newcust" >
                                <span  @mouseover="promo(row.bookmakerid + ',' + compid + ',1,0',$event,'regular')" v-html="numbertoCurrency(row.newcustomer)" v-if="row.newcustomer!=null"></span>
                                <span v-if="row.newcustomer==null">&nbsp;</span>
                            </div>
                        
                            <div v-if="row.allcust" >
                                <span @mouseover="promo(row.bookmakerid + ',' + compid + ',3,0',$event,'allpunters')" v-if="row.allcust!=null" >
                                       <img style="height:20px;width:20px" src="/assets/icons/all-inclusive.png"/>
                                   </span>
                            </div>

                            <div class="cell small-1">
                           
                           <div class="dropdown"  v-if="row.newcustomer > 0">
                               
                           </div>

                       </div>
                       <div class="cell small-1">
                           <div class="dropdown"  v-if="row.promos> 0">
                           
                                   
                           
                           </div>
                       </div>

                      </div>
                    </div>
                </template>

            </div>

            <!-- odds-->
            <div class="grid-x"  v-for="(row,key) in sortedOdds" :key="key" style="text-align:center;border-bottom: 1px #E6E7E9 solid; padding: 1px 0px 1px 0px" >

                    <template v-for="(val,item) in setBO(row, key)">

                        <div v-if="item=='Competitor'" class="cell small-2 qmediumfont" :key="item">
                            {{val}}
                        </div>
                        <!-- <div v-else-if="item=='BO'" class="cell auto" :key="item">

                        </div>-->
                        <div v-else-if="item!=='BO' && item!==null && donotDisplayColumn2(val,item,row) == false" class="cell shrink text-center" style="min-width:36px" :key="item">

                            <div :class="[{ bestodds:oddsStyle(val,bo), oddsout: oddsOut, oddsin: oddsIn, oddupdated: oddUpdated }]" v-html="$formatOddsTS(val,item)" class="qmediumfont">
                             
                            </div> 

                        </div>

                    </template> 

            </div>
            

        </template>

        </div>
    </div>


</template>


<script>

 //   import bannerodds from '../components/adverts/bannerodds.vue';
    import dropdown from '../components/navigation/dropdown.vue';
    import axios from 'axios';

    export default {
        name: "outrightodds",
        data: function () {
            return {
                odds: [],
                league: [],
                banners: [],
                promoheaders: [],
                currentSort: 'Competitor',
                currentSortDir: 'asc',
                oddsOut: false,
                oddsIn: false,
                sortedOdds: null,
                timer: '',
                lastOutrightOddsUpdate: '',
                oddUpdated: false,
                ocorid: parseInt(this.orid),
                advert: "",
                bo: 0,
                filteroptions: [],
                loadstage: 0,
                oddsloaded: false,
                oddsheaders: [],
                compid: '',
                promoheadersloaded: false
            }
        },

        props: {
            orid: String,
            bettype: String,
            compdesc: String

        },
        computed: {
                betinfo() {
                    return this.splittoarray(this.removehyphens(this.bettype),"~");
                },
                competition() {
                    return this.removehyphens(this.compdesc);
                },
                hints () {
                return this.$store.state.showhints;
            }

            },
        created() {

            //what filter options exist in the dropdown
            this.filteroptions = JSON.parse('[{ "D": "Name", "v": "Competitor" },{"D": "high -> low", "v": "BO"}]');

            this.compid = window.location.pathname.split("/").pop();


            //load odds table first
            this.oddsTable();
            this.promoHeaderTable();

            //refresh odds on a timer
            this.timer = setInterval(this.oddsTable, 30000);

        },

        beforeDestroy() {
            clearInterval(this.timer);
        },

        components: {
            dropdown,
           // bannerodds
        },

        asyncComputed: {

            //turn odds data into an array so it can be sorted
            sortedOdds: function () {

                if (!this.oddsloaded == true) return;
              
                return this.odds[0].O.slice().sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }

        },

        methods: {

            reloadData: function (val) {
                alert(val.value);
            },

            sortList: function () {
                this.filteroptions = JSON.parse("[{ 'D': 'Name', v: 'Competitor' },{'D': 'high -> low', 'v', 'BO'}]");
            },

            setBO: function (val) {

                this.bo = val["BO"];

                return val;

            },

            donotDisplayColumn: function (val) {

                let contains = false;

                if (val != null) { 
                    contains = val.includes('_move');

                }
                
                return contains;

            },

            donotDisplayColumn2: function (val, item, row) {

                //default values
                let contains = false;
                this.oddsOut = false;
                this.oddsIn = false;

                if (item != null) {

                    contains = item.includes('_move');

                    //set the move column name
                    let mo = row[item + '_move'];

                    switch (true) {
                        case mo > 0:
                            this.oddsOut = true;
                            this.oddsIn = false;
                            break;
                        case mo < 0:
                            this.oddsOut = false;
                            this.oddsIn = true;
                    }
                }
                return contains;

            },

            //get data for odds table
            oddsTable: function () {

                let bt = this.betinfo[0]

                this.oddsloaded = false;

                axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/OutrightOddsTable", {

                    params: {
                        bettype: bt,
                        orid: this.orid,
                        selectedbookmakers: JSON.stringify(this.$store.state.sb),
                        noitems: 10
                    }
                }).then(response => {
                    this.odds = JSON.parse(response.data);


                    this.oddsheaders = this.odds[0].O[0];
               
                    this.lastOutrightOddsUpdate = new Date();
                    this.oddsloaded = true;
                });
            },


            //get data for odds table
            promoHeaderTable: function () {

                this.promoheadersloaded = false;

                axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/OutrightPromoHeader", {

                    params: {
                        orid: Number(this.orid),
                        bettype: this.betinfo[0],
                        country: this.$store.state.countryname,
                        noitems: 10
                    }

                }).then(response => {

               
                    
                    this.promoheaders = JSON.parse(response.data); this.promoheadersloaded =  true });
            },

            //is odd bo, going out, coming in
            oddsStyle: function (odd, bo) {
                //,mo, lu

        
                //switch (true) {
                //    case mo === 0:
                //        this.oddsOut = false;
                //        this.oddsIn = false;
                //        break;
                //    case mo > 0:
                //        this.oddsOut = true;
                //        this.oddsIn = false;
                //        break;
                //    case mo < 0:
                //        this.oddsOut = false;
                //        this.oddsIn = true;

                //}

                ////odd last changed against time odds last changed in page
                //var OLU = new Date(lu);
                //var OCP = new Date(this.lastOddsUpdate)

                //if (OLU >= OCP && mo != 0) {

                //    this.oddUpdated = true;

                //} else {

                //    this.oddUpdated = false;
                //}

                if (bo === odd) {
                    return true;
                }

            },

            //method called for changing sort on a column
            sort: function (s) {
              
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            }

        }

    };



</script>

<style scoped>


    .oddupdated {
        animation: fade 3s forwards;
        border-top: solid 3px;
        border-top-color: green;
        -webkit-animation: colorchange 5s forwards; /* Chrome and Safari */
    }

    @keyframes colorchange {

        from {
            border-top-color: green;
        }

        to {
            border-top-color: white;
        }
    }

    @-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */ {
        from {
            border-top-color: green;
        }

        to {
            border-top-color: white;
        }
    }
</style>


